'use client';

import { useEffect } from 'react';
import { captureException, MentiError } from '@mentimeter/errors/edge';
import { Box, Button, Heading } from '@mentimeter/ragnar-ui';
import * as React from 'react';
import Logo from './Logo';

export default function GlobalError({ error }: { error: Error }) {
  useEffect(() => {
    captureException(
      new MentiError('Error in root layout', {
        cause: error,
        feature: 'root-layout',
      }),
    );
  }, [error]);
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <title>Mentimeter</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="description"
          content="Interact with your audience using real-time voting. No installations or downloads required - and it's free!"
        />

        <style>
          {`
            html, body {
              width: 100%;
              height: 100%;
              font-family: "MentiText";
            }

            @font-face {
              font-family: "MentiText";
              src:
                local("MentiText"),
                url("https://static.mentimeter.com/static/fonts/web/menti-text/1.4/MentiText-Regular.eot") format("embedded-opentype"),
                url("https://static.mentimeter.com/static/fonts/web/menti-text/1.4/MentiText-Regular.otf") format("opentype"),
                url("https://static.mentimeter.com/static/fonts/web/menti-text/1.4/MentiText-Regular.ttf") format("truetype"),
                url("https://static.mentimeter.com/static/fonts/web/menti-text/1.4/MentiText-Regular.woff") format("woff"),
                url("https://static.mentimeter.com/static/fonts/web/menti-text/1.4/MentiText-Regular.woff2") format("woff2");
            }

            .wrapper {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              width: 100%;
              height: 100%;
            }

            .button {
              background-color: rgb(237, 237, 240);
              border: 1px solid rgb(237, 237, 240);
              border-radius: 9999px;
              cursor: pointer;
              font-family: inherit;
              font-weight: 600;
              margin-top: 4px;
              padding-bottom: 16px;
              padding-left: 32px;
              padding-right: 32px;
              padding-top: 16px;
              text-rendering: optimizelegibility;
              text-wrap-mode: nowrap;
              transition-delay: 0s;
              transition-duration: 0.3s;
              transition-property: all;
              transition-timing-function: ease;
            }

            .button:hover {
              border-color: rgb(228, 229, 233);
              background-color: rgb(228, 229, 233);
              color: rgb(22, 22, 23);
            }

            .text {
                color: rgba(0, 0, 0, 0.75);
                line-height: 1.4;
            }
          `}
        </style>
      </head>
      <body>
        <Box
          minHeight="100vh"
          flexDirection="column"
          alignItems="stretch"
          justifyContent="stretch"
        >
          <Box
            flex="1"
            flexDirection="column"
            alignItems="stretch"
            justifyContent="stretch"
          >
            <Box
              flex="1"
              alignItems="center"
              justifyContent="center"
              py={4}
              px={3}
              bg="#E7E8EB"
            >
              <Logo />
              <Box
                width="90%"
                maxWidth={480}
                alignItems="center"
                borderRadius={4}
                bg="#FFF"
                p={32}
              >
                <Heading as="h1">Something went wrong...</Heading>
                <Button
                  variant="primary"
                  width={1}
                  mt={3}
                  aria-label="Reload page"
                  onClick={() => window.location.reload()}
                >
                  Click to reload page
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </body>
    </html>
  );
}
