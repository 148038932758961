'use client';

import { MentimeterLogoType } from '@mentimeter/ragnar-visuals';
import { LinkClickable } from '@mentimeter/next-navigation';
import * as React from 'react';

export default function Logo() {
  return (
    <LinkClickable
      href="/"
      aria-label="To start page"
      width="100%"
      mb={4}
      alignItems="center"
    >
      <MentimeterLogoType height={25} />
    </LinkClickable>
  );
}
